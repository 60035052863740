import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const About = (props) => {
  const about = props.data.allMarkdownRemark.edges;
  return (
    <Layout bodyClass="page-about">
      <SEO title="About" pathname="/about" />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>About</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="about container pb-5">
        <div className="row justify-content-center">
          {about.map(edge => (
            <div key={edge.node.frontmatter.path} className="col col-md-10 content-box">
              <div className="card-two">
                <div className="card-header text-dark">
                  <div className="card-header-left">
                    {edge.node.frontmatter.image && (
                      <div className="card-image">
                        <img
                          alt={edge.node.frontmatter.title}
                          className="m-1"
                          src={edge.node.frontmatter.image}
                        />
                      </div>
                    )}
                  </div>
                  <div className="card-right">
                    <h2 className="card-title">{edge.node.frontmatter.title}</h2>
                    <ul className="card-meta">
                      <li>
                        <strong>{edge.node.frontmatter.jobtitle}</strong>
                      </li>
                      <li>
                        <OutboundLink target="_blank" className="about-link"
                                      href={edge.node.frontmatter.linkedinurl}>
                          {edge.node.frontmatter.linkedinurl}
                        </OutboundLink>
                      </li>
                      <li>
                        <OutboundLink target="_blank" className="about-link"
                                      href={`mailto:${edge.node.frontmatter.email}`}>
                          {edge.node.frontmatter.email}
                        </OutboundLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="about-content"
                  dangerouslySetInnerHTML={{ __html: edge.node.html }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query AboutQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/about/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
            image
            jobtitle
            linkedinurl
            email
          }
        }
      }
    }
  }
`;

export default About;
